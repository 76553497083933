import React, {useEffect, useState} from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import {Table, Button, Row, Col, Container, Form, Modal} from 'react-bootstrap';
import Pagination from "@mui/lab/Pagination";
import {useDispatch, useSelector} from 'react-redux';
import { useSnackbar } from 'notistack';
import Message from '../components/Message';
import Loader from '../components/Loader';
import axios from '../utils/axios';
//import Paginate from '../components/Paginate'

import {createHW, listTeacherHWs, deleteHW} from '../actions/hwActions';

import {HW_CREATE_RESET} from '../constants/hwConstants';

const TeachHWListView = ({history, match}) => {
  const dispatch = useDispatch ();
  const { enqueueSnackbar } = useSnackbar();

  const userLogin = useSelector (state => state.userLogin);
  const {userInfo} = userLogin;
  const isTeacher = userInfo.role === 'Teacher';
  //console.log ('isTeacher', isTeacher);

  // const hwCreated = useSelector (state => state.hwCreate);
  // const {
  //   loading: loadingCreateQ,
  //   error: errorCreateQ,
  //   success: successCreateQ,
  //   hw: hwCreateInfo,
  // } = hwCreated;

  // const hwList = useSelector (state => state.hwListForTeacher);
  // const {loading: loadingList, error: errorList, hws} = hwList;

  // const hwDeleted = useSelector (state => state.hwDelete);
  // const {
  //   loading: loadingDelete,
  //   error: errorDelete,
  //   success: successDelete,
  // } = hwDeleted;
  const [name, setName] = useState ('');
  const [loading, setLoading] = useState (false);
  const [hws, setHws] = useState ([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  // const [studentList, setStudentList] = useState ([]);
  // const [exerciseVisible, setExerciseVisible] = useState (false);

  // useEffect (
  //   () => {
  //     dispatch ({type: HW_CREATE_RESET});

  //     if (!userInfo || !isTeacher) {
  //       history.push ('/login');
  //     } else {
  //       dispatch (listTeacherHWs ());
  //     }
  //   },
  //   [dispatch, history, userInfo, successCreateQ, successDelete, isTeacher]
  // );

  const getHomeworkList = () => {
    setLoading (true);
    axios.get('/teacher/getHWByTeacher', {
      params: {
        name,
        language: window.localStorage.getItem ('lang') || 'en',
        page,
        pageSize,
      }
    }).then(res => {
      const { list, total } = res.result;
      setHws(list);
      setTotal(total);
    }).finally(() => {
      setLoading (false);
    });
  }

  const createHWHandler = () => {
    // dispatch (createHW ());
    axios.post (`/teacher/createHomeWork`, {
      language: window.localStorage.getItem ('lang') || 'en',
    }).then(res => {
      enqueueSnackbar('Create Homework successfully', { variant: 'success' });
      getHomeworkList();
    });
  };

  const deleteQuestionHandler = id => {
    if (window.confirm ('Are you sure')) {
      // dispatch (deleteHW (id));
      axios.post (`/teacher/deleteHW`, {
        id,
      }).then(res => {
        enqueueSnackbar('Delete Homework successfully', { variant: 'success' });
        getHomeworkList();
      });
    }
  };

  const searchHomeWork = () => {
    // dispatch (listTeacherHWs (name));
    if (page === 1) {
      getHomeworkList();
    } else {
      setPage(1);
    }
  }

  const copy = (id) => {
    axios.post('/teacher/copyHomeWork', {
      id
    }).then(res => {
      // dispatch (listTeacherHWs ());
      getHomeworkList();
    })
  }

  // const openExerciseModal = id => {
  //   axios.get(`/teacher/getStudentExerciseByHomework?id=${id}`).then(res => {
  //     setStudentList(res.result.list);
  //     setExerciseVisible(true);
  //   })
  // }

  // const closeExerciseModal = () => {
  //   setExerciseVisible(false);
  // }

  useEffect(() => {
    getHomeworkList();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {/* {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loadingCreateQ && <Loader />}
      {errorCreateQ && <Message variant="danger">{errorCreateQ}</Message>} */}
      <Row className="align-items-center">
        <Col>
          <h1>My HomeWorks </h1>
        </Col>
        <Col className="text-right">
          <Button className="my-3" onClick={createHWHandler}>
            <i className="fas fa-plus" /> Create New Homeworks
          </Button>
        </Col>
      </Row>
      {loading
        ? <Loader />
        : (
          <Container>
                <Form inline style={{ marginBottom: '10px' }}>
                  <Form.Group controlId="search">
                    <Form.Control
                      className="mr-sm-3 ml-sm-6"
                      type="text"
                      name="name"
                      value={name}
                      onChange={e => setName (e.target.value)}
                      placeholder="Search By NAME"
                    />
                    <Button onClick={searchHomeWork} variant="outline-success">
                      Search
                    </Button>
                  </Form.Group>
                </Form>
                {
                  hws.length > 0 ? (
                    <div>
                      <Table striped bordered hover responsive className="table-sm">
                        <thead>
                          <tr>

                            <th>Name</th>
                            <th>id</th>
                            <th width="400">description</th>
                            <th>classes</th>
                            {/* <th>Exercise</th> */}
                            <th />

                          </tr>
                        </thead>
                        <tbody>
                          {hws.map (hw => (
                            <tr key={hw._id}>
                              <td>{hw.name}</td>
                              <td>{hw._id}</td>
                              <td><div className='ellipsis-3' title={hw.description.replace(/<[^>]*>/g, '')}>{hw.description.replace(/<[^>]*>/g, '')}</div></td>
                              <td>
                                {
                                  hw.classes.map(c => (
                                    <p><a href={`/teach/class/${c._id}/addContent`}>{c.name}</a></p>
                                  ))
                                }
                              </td>
                              {/* <td>
                                <Button onClick={() => openExerciseModal(hw._id)}>Detail</Button>
                              </td> */}
                              <td>
                                <LinkContainer to={`/teach/hw/${hw._id}/edit`}>
                                  <Button variant="light" className="btn-sm">
                                    <i className="fas fa-edit" />
                                  </Button>
                                </LinkContainer>
                                <Button variant="light" className="btn-sm" onClick={() => copy(hw._id)}>
                                  <i className="fas fa-copy" />
                                </Button>
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => deleteQuestionHandler (hw._id)}
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </td>

                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Pagination
                        count={Math.ceil(total / pageSize)}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                      />
                    </div>
                  ) : (
                    <Message variant="info">No Homeworks found, change language or search again </Message>
                  )
                }
                
              </Container>
        )}
        {/* <Modal show={exerciseVisible} onHide={closeExerciseModal}>
          <Modal.Header closeButton>
              <Modal.Title>Exercise</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Table striped bordered hover responsive className="table-sm">
                  <thead>
                      <tr>
                          <th>Name</th>
                          <th>Accuracy Tracker</th>
                      </tr>
                  </thead>
                  <tbody>
                      {
                          studentList.map((student) => (
                              <tr key={student.student._id}>
                                  <td>{student.student.name}</td>
                                  <td>{student.correct}/{student.total}</td>
                              </tr>
                          ))
                      }
                  </tbody>
              </Table>
          </Modal.Body>
        </Modal> */}
    </Container>
  );
};

export default TeachHWListView;
