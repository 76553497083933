export const QUESTION_CREATE_REQUEST = 'QUESTION_CREATE_REQUEST';
export const QUESTION_CREATE_SUCCESS = 'QUESTION_CREATE_SUCCESS';
export const QUESTION_CREATE_FAIL = 'QUESTION_CREATE_FAIL';
export const QUESTION_CREATE_RESET = 'QUESTION_CREATE_RESET';

export const QUESTION_UPDATE_REQUEST = 'QUESTION_UPDATE_REQUEST';
export const QUESTION_UPDATE_SUCCESS = 'QUESTION_UPDATE_SUCCESS';
export const QUESTION_UPDATE_FAIL = 'QUESTION_UPDATE_FAIL';
export const QUESTION_UPDATE_RESET = 'QUESTION_UPDATE_RESET';

export const QUESTION_LIST_REQUEST = 'QUESTION_LIST_REQUEST';
export const QUESTION_LIST_SUCCESS = 'QUESTION_LIST_SUCCESS';
export const QUESTION_LIST_FAIL = 'QUESTION_LIST_FAIL';

export const QUESTION_LIST_FOR_TEACHER_REQUEST =
  'QUESTION_LIST_FOR_TEACHER_REQUEST';
export const QUESTION_LIST_FOR_TEACHER_SUCCESS =
  'QUESTION_LIST_FOR_TEACHER_SUCCESS';
export const QUESTION_LIST_FOR_TEACHER_FAIL = 'QUESTION_LIST_FOR_TEACHER_FAIL';
export const QUESTION_LIST_FOR_TEACHER_RESET =
  'QUESTION_LIST_FOR_TEACHER_RESET';

export const QUESTION_DETAILS_REQUEST = 'QUESTION_DETAILS_REQUEST';
export const QUESTION_DETAILS_SUCCESS = 'QUESTION_DETAILS_SUCCESS';
export const QUESTION_DETAILS_FAIL = 'QUESTION_DETAILS_FAIL';

export const QUESTION_DELETE_SUCCESS = 'QUESTION_DELETE_SUCCESS';
export const QUESTION_DELETE_REQUEST = 'QUESTION_DELETE_REQUEST';
export const QUESTION_DELETE_FAIL = 'QUESTION_DELETE_FAIL';
