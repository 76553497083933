export const CLASS_CREATE_REQUEST = 'CLASS_CREATE_REQUEST';
export const CLASS_CREATE_SUCCESS = 'CLASS_CREATE_SUCCESS';
export const CLASS_CREATE_FAIL = 'CLASS_CREATE_FAIL';
export const CLASS_CREATE_RESET = 'CLASS_CREATE_RESET';

export const CLASS_UPDATE_REQUEST = 'CLASS_UPDATE_REQUEST';
export const CLASS_UPDATE_SUCCESS = 'CLASS_UPDATE_SUCCESS';
export const CLASS_UPDATE_FAIL = 'CLASS_UPDATE_FAIL';
export const CLASS_UPDATE_RESET = 'CLASS_UPDATE_RESET';

export const CLASS_LIST_REQUEST = 'CLASS_LIST_REQUEST';
export const CLASS_LIST_SUCCESS = 'CLASS_LIST_SUCCESS';
export const CLASS_LIST_FAIL = 'CLASS_LIST_FAIL';

export const CLASS_LIST_FOR_TEACHER_REQUEST = 'CLASS_LIST_FOR_TEACHER_REQUEST';
export const CLASS_LIST_FOR_TEACHER_SUCCESS = 'CLASS_LIST_FOR_TEACHER_SUCCESS';
export const CLASS_LIST_FOR_TEACHER_FAIL = 'CLASS_LIST_FOR_TEACHER_FAIL';
export const CLASS_LIST_FOR_TEACHER_RESET = 'CLASS_LIST_FOR_TEACHER_RESET';

export const CLASS_DETAILS_REQUEST = 'CLASS_DETAILS_REQUEST';
export const CLASS_DETAILS_SUCCESS = 'CLASS_DETAILS_SUCCESS';
export const CLASS_DETAILS_FAIL = 'CLASS_DETAILS_FAIL';

export const CLASS_DELETE_SUCCESS = 'CLASS_DELETE_SUCCESS';
export const CLASS_DELETE_REQUEST = 'CLASS_DELETE_REQUEST';
export const CLASS_DELETE_FAIL = 'CLASS_DELETE_FAIL';

export const ADD_HW_TO_CLASS_REQUEST = 'ADD_HW_TO_CLASS_REQUEST';
export const ADD_HW_TO_CLASS_SUCCESS = 'ADD_HW_TO_CLASS_SUCCESS';
export const ADD_HW_TO_CLASS_FAIL = 'ADD_HW_TO_CLASS_FAIL';
export const ADD_HW_TO_CLASS_RESET = 'ADD_HW_TO_CLASS_RESET';

export const DELETE_HW_FROM_CLASS_REQUEST = 'DELETE_HW_FROM_CLASS_REQUEST';
export const DELETE_HW_FROM_CLASS_SUCCESS = 'DELETE_HW_FROM_CLASS_SUCCESS';
export const DELETE_HW_FROM_CLASS_FAIL = 'DELETE_HW_FROM_CLASS_FAIL';
export const DELETE_HW_FROM_CLASS_RESET = 'DELETE_HW_FROM_CLASS_RESET';
