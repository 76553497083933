export const REGISTER_CREATE_REQUEST = 'REGISTER_CREATE_REQUEST';
export const REGISTER_CREATE_SUCCESS = 'REGISTER_CREATE_SUCCESS';
export const REGISTER_CREATE_FAIL = 'REGISTER_CREATE_FAIL';
export const REGISTER_CREATE_RESET = 'REGISTER_CREATE_RESET';

export const STUDENT_LIST_IN_CLASS_REQUEST = 'STUDENT_LIST_IN_CLASS_REQUEST';
export const STUDENT_LIST_IN_CLASS_SUCCESS = 'STUDENT_LIST_IN_CLASS_SUCCESS';
export const STUDENT_LIST_IN_CLASS_FAIL = 'STUDENT_LIST_IN_CLASS_FAIL';
export const STUDENT_LIST_IN_CLASS_RESET = 'STUDENT_LIST_IN_CLASS_RESET';

export const REMOVE_STUDENT_FROM_CLASS_REQUEST =
  'REMOVE_STUDENT_FROM_CLASS_REQUEST';
export const REMOVE_STUDENT_FROM_CLASS_SUCCESS =
  'REMOVE_STUDENT_FROM_CLASS_SUCCESS';
export const REMOVE_STUDENT_FROM_CLASS_FAIL = 'REMOVE_STUDENT_FROM_CLASS_FAIL';
export const REMOVE_STUDENT_FROM_CLASS_RESET =
  'REMOVE_STUDENT_FROM_CLASS_RESET';

export const MY_CLASS_LIST_REQUEST = 'MY_CLASS_LIST_REQUEST';
export const MY_CLASS_LIST_SUCCESS = 'MY_CLASS_LIST_SUCCESS';
export const MY_CLASS_LIST_FAIL = 'MY_CLASS_LIST_FAIL';
export const MY_CLASS_LIST_RESET = 'MY_CLASS_LIST_RESET';
